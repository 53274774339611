import React, { useState, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import {Link} from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"
import {
    redirectPageWithLocale
} from "../../utils/util"
import logo from "../../assets/images/AI-01.png"
import vietnam from "../../assets/images/vietnam.png"
import english from "../../assets/images/english.png"

const NavbarTwo = ({
    bgWhite=true
}) => {
    const intl = useIntl()
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);
    const [currentLang, setLanguage] = useState(intl.locale);
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    const onChangeLanguage = (e, mode) => {
        e.preventDefault();
        setLanguage(mode);
        redirectPageWithLocale(mode);
    }

    useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className={`navbar-area${bgWhite ? " navbar-style-two": ""}`}>
            <div className="tarn-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link 
                                to="/"
                                onClick={() => setCollapsed(true)} 
                                className="navbar-brand navbar-brand__img"
                            >
                                <img src={logo} alt="logo" />
                            </Link>

                            <button 
                                onClick={toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link 
                                            to={`/${currentLang}/`} 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            {intl.formatMessage({ id: "home" })}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link 
                                            to={`/${currentLang}/services`} 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            {intl.formatMessage({ id: "services" })}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link 
                                            to={`/${currentLang}/about-us`} 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            {intl.formatMessage({ id: "aboutUs" })}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link 
                                            to={`/${currentLang}/blog`} 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            {intl.formatMessage({ id: "blogs" })}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link 
                                            to={`/${currentLang}/contact`} 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            {intl.formatMessage({ id: "contact" })}
                                        </Link>
                                    </li>
                                </ul>
                            
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item select-mul-lang">
                                        <img 
                                            src={vietnam} 
                                            alt="vietnam.png" 
                                            style={{ opacity: currentLang === 'vi' ? "1" : "0.3" }}
                                            onClick={(e) => onChangeLanguage(e, 'vi')}
                                        />
                                        <img 
                                            hidden={true}
                                            src={english} 
                                            alt="english.png" 
                                            style={{ opacity: currentLang === 'en' ? "1" : "0.3" }}
                                            onClick={(e) => onChangeLanguage(e, 'en')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NavbarTwo
