import { navigate } from "gatsby";
const LOCALE = ["en", "vi"];

export function redirectPageWithLocale(locale) {
  let arrayPathName = window.location.pathname.split("/");
  const indexLocaleInPathName = arrayPathName.findIndex(obj => LOCALE.includes(obj));
  if(indexLocaleInPathName !== -1) {
      arrayPathName[indexLocaleInPathName] = locale;
  } else {
    navigate(`/${locale}`); // redirect to homepage if not matching anything
    return;
  }
  navigate(arrayPathName.join("/"));
}