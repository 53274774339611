import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/AI-01.png"
import footerMap from "../../assets/images/footer-map.png"
import { useIntl } from "gatsby-plugin-intl"

const Footer = () => {
    const intl = useIntl()
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-8">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>{intl.formatMessage({ id: "home.Footer.Title" })}</p>

                            <ul className="social-link">
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-8">
                        <div className="single-footer-widget pl-5">
                            <h3>{intl.formatMessage({ id: "home.Footer.Explore" })}</h3>
                            
                            <ul className="footer-links-list footer-link-list__content">
                                <li>
                                    <Link to="/">
                                    {intl.formatMessage({ id: "home.Footer.Explore.Home" })}
                                    </Link>
                                </li>
                                    <li>
                                    <Link to="/about-us">
                                    {intl.formatMessage({ id: "home.Footer.Explore.AboutUs" })}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services">
                                    {intl.formatMessage({ id: "home.Footer.Explore.Services" })}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog">
                                    {intl.formatMessage({ id: "home.Footer.Explore.Blog" })}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                    {intl.formatMessage({ id: "home.Footer.Explore.Contact" })}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-8">
                        <div className="single-footer-widget">
                            <h3>{intl.formatMessage({ id: "home.Footer.Address" })}</h3>

                            <ul className="footer-contact-info footer-link-list__content">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    {intl.formatMessage({ id: "home.Footer.Address.AddressInfo" })}
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href={`tel:${intl.formatMessage({ id: "home.Footer.Address.Phone" })}`}>{intl.formatMessage({ id: "home.Footer.Address.Phone" })}</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href={`mailto:${intl.formatMessage({ id: "home.Footer.Address.Email" })}`}>{intl.formatMessage({ id: "home.Footer.Address.Email" })}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>AITech Asia</strong> All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;