import React from 'react'
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../../../static/favicon.ico"

const SEO = ({ title, keywords, meta, lang, description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
            lang
          }
        }
      }
    `
  )
  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaLang = site.siteMetadata.lang || "en";
  const metaKeywords = keywords || site.siteMetadata.keywords;

  // const image =
  //   metaImage && metaImage.src
  //     ? `${site.siteMetadata.siteUrl}${metaImage.src}`
  //     : null
  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <link rel="icon" href={favicon} />
        <meta name="description" content={metaDescription} />

        <meta name="og:title" property="og:title" content={metaTitle}></meta>
        <meta name="og:description" property="og:description" content={metaDescription}></meta>
        
        <link rel="canonical" href={site.siteMetadata.siteUrl}></link>
        <meta name="keywords" content={metaKeywords.join(`, `)}></meta>
        <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
        <meta name="og:type" property="og:type" content="website"></meta>
        <meta name="twitter:card" content="summary"></meta>
        <meta name="twitter:creator" content={site.siteMetadata.author}></meta>
      </Helmet>
    </div>
  )
}

export default SEO
